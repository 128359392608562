import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
//import Services from "../components/Sections/Services";
//import Projects from "../components/Sections/Projects";
//import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import FloatingButton from "../components/Nav/FloatingButton"; // Import the floating button component

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      
      <Pricing />
      <Contact />
      <Footer />

      <FloatingButton />
    </>
  );
}


