import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border, icon }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}{title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => (props.border ? "#EF5730" : "#EF5730")};
  background-color: ${(props) => (props.border ? "transparent" : "#EF5730")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#EF5730" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#0B093B")};
    border: 1px solid #0B093B;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  }
`;

const IconWrapper = styled.span`
  margin-right: 10px; // Adjust as needed
  display: flex;
  align-items: center;
`;
