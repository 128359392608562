import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  const openWhatsApp = () => {
    const phoneNumber = "+628161368368"; // Replace with your phone number
    const message = "Hello, I am interested in your services."; // Replace with your message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <p className="font13">
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="monitor"
                price="14,99 mio"
                title="Eco"
                text="Perfect to fit your budget."
                offers={[
                  { name: "Self Healing", cheked: true },
                  { name: "Anti Yellowing", cheked: true },
                  { name: "Gloss Level", cheked: true },
                  { name: "Thickness 190micron", cheked: true },
                  { name: "Durability 4 years", cheked: true },
                ]}
                action={openWhatsApp}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="24,99 mio"
                title="Careplus"
                text="Best protection, worth every cents of your money."
                offers={[
                  { name: "Self Healing", cheked: true },
                  { name: "Anti Yellowing", cheked: true },
                  { name: "Gloss Level", cheked: true },
                  { name: "Thickness 190micron", cheked: true },
                  { name: "Durability 10 years", cheked: true },
                ]}
                action={openWhatsApp}
              />
            </TableBox>
            
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 45%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




