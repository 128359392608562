import React from "react";
import styled from "styled-components";
import { ReactComponent as WhatsAppIcon } from "../../assets/svg/whatsapp.svg"; // Adjust the path as needed
import { ReactComponent as InstagramIcon } from "../../assets/svg/instagram.svg"; // Adjust the path as needed

const FloatingButtons = () => {
  const openWhatsApp = () => {
    const phoneNumber = "+628161368368"; // Replace with your phone number
    const message = "Hello, I am interested in your services."; // Replace with your message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const openInstagram = () => {
    const url = "https://www.instagram.com/adaptivppf"; // Replace with your Instagram profile URL
    window.open(url, "_blank");
  };

  return (
    <>
      <Button onClick={openInstagram} style={{ bottom: '90px', backgroundColor: '#E4405F' }}>
        <InstagramIcon />
      </Button>
      <Button onClick={openWhatsApp}>
        <WhatsAppIcon />
      </Button>
    </>
  );
};

const Button = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #25D366;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;

  svg {
    width: 30px;
    height: 30px;
    fill: white;
  }

  &:hover {
    background-color: #128C7E;
  }
`;

export default FloatingButtons;